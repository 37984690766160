.bg-blur {
  z-index: 999;
  backdrop-filter: blur(3px);
  background-color: rgba(6, 0, 8, 0.8);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .top-bar-wrapper {
    z-index: 50;
    height: 3rem;
    width: 100%;
    background-color: #00000091;
    display: flex;
    justify-content: flex-end;
    .icons-wrapper {
      height: 3rem;
      width: 12rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      cursor: pointer;
    }
  }
  .content {
    top: 0;
    height: calc(100% - 3rem);
    width: 100%;
    justify-content: center;
    display: flex;
    .gallery-wrapper {
      margin: 10px 0px;
      padding: 5px;
      width: 90%;
      height: calc(100% - 20px);
    }
  }
}

.p-galleria.p-component.p-ripple-disabled {
  height: 100%;
}

.p-galleria-content {
  height: 100% !important;
}
.p-galleria-item-wrapper {
  filter: drop-shadow(1px 1px 5px rgba(19, 19, 19, 0.4));
  height: 100%;
}
